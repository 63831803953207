<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { createEventDispatcher } from 'svelte';
	import Spouse from 'models/Spouse';
	import { isPresent, isBlank } from 'utils/tools';
	import Input from '../../components/ui/input.svelte';
	import Checkbox from '../../components/ui/checkbox.svelte';
	import Datepicker from '../../components/ui/datepicker.svelte';
	import DadateAddress from '../../components/ui/input_dadata_address.svelte';
	import DadataPassportDepartment from '../../components/ui/input_dadata_passport_department.svelte';
	import Case from 'case';

	const dispatch = createEventDispatcher();

	export let spouseId;
	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;
	export let submitButtonDisabled;

	let mailingAddressCheck = false;
	let mailingAddressCheckInit = false;
	let fullName = null;

	$: spouse = Object.entries(Spouse.find(spouseId) || Spouse.new()).reduce((res, [key, value]) => {
		if (key == 'marriage') {
			res = { ...res, ...Object.entries(value).reduce((mRes, [k, v]) => (mRes = { ...mRes, [`marriage_${k}`]: v }), {}) };
		} else {
			res[key] = value;
		}
		return res;
	}, {});
	//$: marriage = spouse?.marriage || {}
	$: procedure = $transmitter?.procedure || procedure;
	$: bankrupt = procedure?.bankrupt;
	$: spouse.marriage_bankrupt_id = bankrupt?.id;

	const initFullName = () => {
		fullName = [spouse.surname, spouse.name, spouse.patronymic].filter(n => isPresent(n)).join(' ');
	};

	$: if (fullName === null && !!spouse.id) {
		initFullName();
	}

	$: if (![spouse.full_name, null].includes(fullName)) {
		[spouse.surname, spouse.name, spouse.patronymic] = fullName.split(' ');
	}

	$: if (!mailingAddressCheckInit) {
		mailingAddressCheck = spouse && spouse.reg_address_full && spouse.mailing_address_full && spouse.reg_address_full === spouse.mailing_address_full;
		mailingAddressCheckInit = true;
	}

	const setMailingAddress = (mailingAddressCheck, regAddressFull) => {
		if (spouse && mailingAddressCheck) {
			spouse.mailing_address_full = regAddressFull;
		}
	};

	$: setMailingAddress(mailingAddressCheck, spouse.reg_address_full);

	$: hasPreviousFullName = spouse && spouse.full_name_previous;
	$: changedFullNameCheck = hasPreviousFullName;

	const nullifyPreviousFullName = () => {
		if (spouse) {
			spouse.full_name_previous = null;
		}
	};

	$: if (!changedFullNameCheck) {
		nullifyPreviousFullName();
	}

	// Расторжение брака
	const nullifyMarriageDissolvedData = () => {
		if (spouse) {
			spouse.marriage_divorce_certificate_number = null;
			spouse.marriage_divorce_certificate_date = null;
			//spouse.marriage_division_common_property_judicial_acts = false;
		}
	};

	$: if (!spouse.marriage_marriage_dissolved) {
		nullifyMarriageDissolvedData();
	}

	// Соглашение о разделе общего имущества
	const nullifyСommonPropertyDivisionAgreementData = () => {
		if (spouse) {
			spouse.marriage_common_property_division_agreement_number = null;
			spouse.marriage_common_property_division_agreement_date = null;
		}
	};

	$: if (!spouse.marriage_common_property_division_agreement) {
		nullifyСommonPropertyDivisionAgreementData();
	}

	// Брачный контракт
	const nullifyMarriageContractData = () => {
		if (spouse) {
			spouse.marriage_marriage_contract_number = null;
			spouse.marriage_marriage_contract_date = null;
		}
	};

	$: if (!spouse.marriage_marriage_contract) {
		nullifyMarriageContractData();
	}

	// Судебный акт о разделе общего имущества
	const nullifyDivisionCommonPropertyJudicialActData = () => {
		if (spouse) {
			spouse.marriage_division_common_property_judicial_act_number = null;
			spouse.marriage_division_common_property_judicial_act_date = null;
		}
	};

	$: if (!spouse.marriage_division_common_property_judicial_acts) {
		nullifyDivisionCommonPropertyJudicialActData();
	}

	const formFields = {
		fullName: {
			model: 'spouse',
			attribute: 'full_name',
			label: 'ФИО супруга(и)',
			placeholder: 'Фамилия Имя Отчество',
			required: true,
		},
		previousFullName: {
			model: 'spouse',
			attribute: 'full_name_previous',
			label: 'Предыдущие ФИО',
			placeholder: 'Фамилия Имя Отчество',
		},
		inn: {
			model: 'spouse',
			attribute: 'inn',
			label: 'ИНН супруга(и)',
			placeholder: '123456789012',
			maskOptions: { mask: '000000000000' },
		},
		snils: {
			model: 'spouse',
			attribute: 'snils',
			label: 'СНИЛС супруга(и)',
			placeholder: '123-456-789 10',
			maskOptions: { mask: '000-000-000 00' },
		},
		passportNumber: {
			model: 'spouse',
			attribute: 'passport_number',
			label: 'Паспорт супруга(и)',
			placeholder: '9900 123456',
			maskOptions: { mask: '0000 000000' },
		},
		passportDepartment: {
			model: 'spouse',
			attribute: 'passport_department',
			label: 'кем выдан',
			placeholder: 'Московским РОВД',
		},
		passportDepartmentCode: {
			model: 'spouse',
			attribute: 'passport_department_code',
			label: 'код подразделения',
			placeholder: '700-052',
			maskOptions: { mask: '000-000' },
		},
		passportDate: {
			model: 'spouse',
			attribute: 'passport_date',
			label: 'когда выдан',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		birthDate: {
			model: 'spouse',
			attribute: 'birth_date',
			label: 'Дата рождения супруга(и)',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		birthPlace: {
			model: 'spouse',
			attribute: 'birth_place',
			label: 'Место рождения супруга(и) (как в паспорте)',
			placeholder: 'гор. Москва',
		},
		regAddress: {
			model: 'spouse',
			attribute: 'reg_address_full',
			label: 'Адрес регистрации супруга(и)',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
		},
		mailingAddress: {
			model: 'spouse',
			attribute: 'mailing_address_full',
			label: 'Почтовый адрес супруга(и)',
			placeholder: '100000, Московская область, г. Королев, ул. Вторая, д. 2, кв. 2',
		},
		marriageCertificateNumber: {
			model: 'spouse',
			attribute: 'marriage_certificate_number',
			label: 'Свидетельство о браке',
			placeholder: '0-00 №000000',
		},
		marriageCertificateDepartment: {
			model: 'spouse',
			attribute: 'marriage_certificate_department',
			label: 'кем выдано',
			placeholder: 'ЗАГС',
		},
		marriageCertificateDate: {
			model: 'spouse',
			attribute: 'marriage_certificate_date',
			label: 'Дата заключения брака',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		divorceCertificateNumber: {
			model: 'spouse',
			attribute: 'divorce_certificate_number',
			label: 'Свидетельство о расторжении брака',
			placeholder: '0-00 №000000',
		},
		divorceCertificateDate: {
			model: 'spouse',
			attribute: 'divorce_certificate_date',
			label: 'когда выдано',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		divorceDate: {
			model: 'spouse',
			attribute: 'divorce_date',
			label: 'Дата расторжения брака',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		mailingAddressCheck: {
			text: 'тот же, что и адрес регистрации',
		},
		changedFullNameCheck: {
			label: 'Супруг(а) менял(а) ФИО',
			text: 'да, менял',
		},
		commonPropertyDivisionAgreementCheck: {
			model: 'spouse',
			attribute: 'common_property_division_agreement',
			label: 'Соглашение о разделе общего имущества заключалось',
			text: 'да, заключалось',
		},
		commonPropertyDivisionAgreementNumber: {
			model: 'spouse',
			attribute: 'common_property_division_agreement_number',
			label: 'Номер соглашения о разделе общего имущества',
			placeholder: '123',
		},
		commonPropertyDivisionAgreementDate: {
			model: 'spouse',
			attribute: 'common_property_division_agreement_date',
			label: 'Дата соглашения о разделе общего имущества',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		commonPropertyDivisionAgreementNotaryLicenseNumber: {
			model: 'spouse',
			attribute: 'common_property_division_agreement_notary_license_number',
			label: 'Номер лицензии нотариуса',
			placeholder: 'Номер',
		},
		marriageContractNumber: {
			model: 'spouse',
			attribute: 'marriage_contract_number',
			label: 'Номер брачного договора',
			placeholder: '123',
		},
		marriageContractDate: {
			model: 'spouse',
			attribute: 'marriage_contract_date',
			label: 'Дата брачного договора',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		marriageContractNotaryLicenseNumber: {
			model: 'spouse',
			attribute: 'marriage_contract_notary_license_number',
			label: 'Номер лицензии нотариуса',
			placeholder: 'Номер',
		},
		divisionCommonPropertyJudicialActNumber: {
			model: 'spouse',
			attribute: 'division_common_property_judicial_act_number',
			label: 'Номер судебного акта о разделе общего имущества',
			placeholder: '123',
		},
		divisionCommonPropertyJudicialActDate: {
			model: 'spouse',
			attribute: 'division_common_property_judicial_act_date',
			label: 'Дата судебного акта о разделе общего имущества',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		marriageContractCheck: {
			model: 'spouse',
			attribute: 'marriage_contract',
			label: 'Брачный договор заключался',
			text: 'да, заключался',
		},
		divisionCommonPropertyJudicialActsCheck: {
			model: 'spouse',
			attribute: 'division_common_property_judicial_acts',
			label: 'Судебные акты о разделе общего имущества выносились',
			text: 'да, выносились',
		},
		divisionCommonPropertyCourtName: {
			model: 'spouse',
			attribute: 'division_common_property_court_name',
			label: 'Наименование суда',
			text: 'Суд',
		},
		marriageDissolvedCheck: {
			model: 'spouse',
			attribute: 'marriage_dissolved',
			label: 'Брак расторгнут',
			text: 'да, расторгнут',
		},
	};

	$: submitButtonDisabled = isBlank(fullName);

	handleSubmit = async () => {
		spouse = Object.entries(spouse).reduce(
			(res, [key, value]) => {
				if (key.startsWith('marriage_')) {
					res.marriage[key.replace(/^marriage_/, '')] = value;
				} else {
					res[key] = value;
				}
				return res;
			},
			{ marriage: {} },
		);

		const result = isPresent(spouseId) ? await Spouse.update(spouse) : await Spouse.create(spouse);

		if (isPresent(result.errors)) {
			for (let attribute of Object.keys(formFields)) {
				formFields[attribute] = { ...formFields[attribute], errors: result.errors[Case.snake(attribute)] };
			}
			throw {};
		} else {
			dispatch('submit');
		}
	};

	handleDelete = async () => {
		await Spouse.delete(spouseId);
		dispatch('delete');
	};

	handleCancel = () => {
		spouse = Spouse.new();
	};
</script>

<Input {...formFields.fullName} bind:value={fullName} />
<Checkbox {...formFields.changedFullNameCheck} bind:checked={changedFullNameCheck} />
{#if changedFullNameCheck}
	<Input {...formFields.previousFullName} bind:value={spouse.full_name_previous} />
{/if}
<Input {...formFields.inn} bind:value={spouse.inn} />
<Input {...formFields.snils} bind:value={spouse.snils} />
<Input {...formFields.passportNumber} bind:value={spouse.passport_number} />
<DadataPassportDepartment {...formFields.passportDepartment} bind:value={spouse.passport_department} />
<Input {...formFields.passportDepartmentCode} bind:value={spouse.passport_department_code} />
<Datepicker {...formFields.passportDate} bind:value={spouse.passport_date} />
<Datepicker {...formFields.birthDate} bind:value={spouse.birth_date} />
<Input {...formFields.birthPlace} bind:value={spouse.birth_place} />
<DadateAddress {...formFields.regAddress} bind:value={spouse.reg_address_full} />
<DadateAddress {...formFields.mailingAddress} disabled={mailingAddressCheck} bind:value={spouse.mailing_address_full} />
<Checkbox {...formFields.mailingAddressCheck} bind:checked={mailingAddressCheck} />
<Input {...formFields.marriageCertificateNumber} bind:value={spouse.marriage_marriage_certificate_number} />
<Input {...formFields.marriageCertificateDepartment} bind:value={spouse.marriage_marriage_certificate_department} />
<Datepicker {...formFields.marriageCertificateDate} bind:value={spouse.marriage_marriage_certificate_date} />
<Checkbox {...formFields.commonPropertyDivisionAgreementCheck} bind:checked={spouse.marriage_common_property_division_agreement} />
{#if spouse.marriage_common_property_division_agreement}
	<Input {...formFields.commonPropertyDivisionAgreementNumber} bind:value={spouse.marriage_common_property_division_agreement_number} />
	<Datepicker {...formFields.commonPropertyDivisionAgreementDate} bind:value={spouse.marriage_common_property_division_agreement_date} />
{/if}
<Input
	{...formFields.commonPropertyDivisionAgreementNotaryLicenseNumber}
	bind:value={spouse.marriage_common_property_division_agreement_notary_license_number}
/>
<Checkbox {...formFields.marriageContractCheck} bind:checked={spouse.marriage_marriage_contract} />
<Checkbox {...formFields.divisionCommonPropertyJudicialActsCheck} bind:checked={spouse.marriage_division_common_property_judicial_acts} />
<Input {...formFields.divisionCommonPropertyCourtName} bind:value={spouse.marriage_division_common_property_court_name} />
{#if spouse.marriage_marriage_contract}
	<Input {...formFields.marriageContractNumber} bind:value={spouse.marriage_marriage_contract_number} />
	<Datepicker {...formFields.marriageContractDate} bind:value={spouse.marriage_marriage_contract_date} />
{/if}
<Input {...formFields.marriageContractNotaryLicenseNumber} bind:value={spouse.marriage_marriage_contract_notary_license_number} />
<Checkbox {...formFields.marriageDissolvedCheck} bind:checked={spouse.marriage_marriage_dissolved} />
{#if spouse.marriage_marriage_dissolved}
	<Input {...formFields.divorceCertificateNumber} bind:value={spouse.marriage_divorce_certificate_number} />
	<Datepicker {...formFields.divorceCertificateDate} bind:value={spouse.marriage_divorce_certificate_date} />
	<Datepicker {...formFields.divorceDate} bind:value={spouse.marriage_divorce_date} />
	{#if spouse.marriage_division_common_property_judicial_acts}
		<Input {...formFields.divisionCommonPropertyJudicialActNumber} bind:value={spouse.marriage_division_common_property_judicial_act_number} />
		<Datepicker {...formFields.divisionCommonPropertyJudicialActDate} bind:value={spouse.marriage_division_common_property_judicial_act_date} />
	{/if}
{/if}
