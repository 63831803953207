<!-- @format -->
<script>
	import { procedure, fieldsReady, autosaveStatus, transmitter } from 'base_stores';
	import { dctId, dctFormAvailable, currentData } from '~/svelte/dcts/stores/stores';
	import { onMount } from 'svelte';
	import { showModal } from '~/svelte/components/modal.svelte';
	import { isArray, isBlank, isPresent } from 'utils/tools';
	import { fetchPut } from 'utils/fetch';
	import Spouse from 'models/Spouse';
	import SpouseModal from '~/svelte/procedures/spouses/_modal.svelte';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';

	export let fieldId;
	export let disabled = false;

	let selectedSpouseIds = [];
	let currentValue = [];
	let spouses = [];
	let spouseId = null;
	let indexCollapse = true;
	let infoShow = false;
	let currentDctId;
	let fetching = false;

	const openModalForm = id => {
		spouseId = id;
		showModal('form-spouse-modal');
	};

	const handleChange = async () => {
		$autosaveStatus = null;
		let params = { dct: { spouses_ids: selectedSpouseIds }, procedure_id: $procedure.id };
		fetching = true;

		try {
			const response = await fetchPut(`/api/private/documents/${$dctId}`, params);
			if (JSON.stringify(response) != '{}') {
				$autosaveStatus = 'saved';
			}
			currentValue = selectedSpouseIds;
			const dct = response.dct;
			const dctIndex = $transmitter.dcts.findIndex(d => d.id == dct.id);
			//$transmitter.dcts[dctIndex] = dct;
			$transmitter = { ...$transmitter, ...response };
		} catch (error) {
			console.error(error);
			$autosaveStatus = 'not_saved';
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: if (JSON.stringify(currentValue) != JSON.stringify(selectedSpouseIds) && currentDctId == $dctId && !fetching) {
		handleChange();
	}

	$: if (currentDctId !== $dctId) {
		currentDctId = $dctId;
		selectedSpouseIds = isPresent($currentData.spousesIds) ? $currentData.spousesIds : spouses.map(spouse => spouse.id);
		currentValue = selectedSpouseIds;
	}

	$: if (isBlank(selectedSpouseIds) && $currentData.requestObjects !== 'bankrupt') {
		selectedSpouseIds = isPresent($currentData.spousesIds) ? $currentData.spousesIds.filter(id => spouses.includes(id)) : spouses.map(spouse => spouse.id);
	}

	onMount(async () => {
		$fieldsReady[fieldId] = false;
		spouses = await Spouse.loadSpouses();
		$fieldsReady[fieldId] = true;
	});
</script>

{#if $currentData.requestObjects !== 'bankrupt'}
	<div class="form-group row" role="button" tabindex="0" on:click={$dctFormAvailable && (() => (indexCollapse = !indexCollapse))} on:keypress|stopPropagation>
		<h4 class="spouses button_with_info_wrapper col-sm-12">
			<span class="hoverable">
				<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
				<span class="header m-l-xs">{`Супруги (${spouses.length})`}</span>
			</span>
			<div
				class="m-l-sm"
				role="button"
				tabindex="0"
				on:mouseover={$dctFormAvailable && (() => (infoShow = true))}
				on:mouseout={() => (infoShow = false)}
				on:focus={() => (infoShow = true)}
				on:blur={() => (infoShow = false)}
			>
				<IconInfo />
			</div>
			{#if infoShow}
				<div class="order-of-repayment__popover show fade m-b-xs">
					Указываются текущий(ая) супруг(а) должника и предыдущие, с кем брак был расторгнут не позднее 3 лет с даты судебного акта о принятии заявления
				</div>
			{/if}
		</h4>
	</div>
	<div id="collapse-spouses" class="collapse row m-t-n" class:show={indexCollapse}>
		<div class="col-sm-12">
			<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && openModalForm(null)}> + Супруг(а) </button>
			<div class="p-1">
				{#if isArray(spouses)}
					<ol class="block-list">
						{#each spouses as spouse}
							<li class="d-flex">
								<div class="float-left d-flex align-self-center">
									<input disabled={disabled || !$dctFormAvailable} type="checkbox" bind:group={selectedSpouseIds} class="m-r-sm" value={spouse.id} />
								</div>
								<div
									class="float-left"
									class:simulated-link={$dctFormAvailable}
									class:simulated-link-disabled={!$dctFormAvailable}
									role="button"
									tabindex="0"
									on:click={$dctFormAvailable && openModalForm(spouse.id)}
									on:keypress|stopPropagation
								>
									{spouse.full_name || ''}
								</div>
							</li>
						{/each}
					</ol>
				{/if}
			</div>
		</div>
	</div>
	<SpouseModal
		{spouseId}
		disabled={!$dctFormAvailable}
		on:submit={() => (spouses = Spouse.all())}
		on:delete={() => {
			spouses = Spouse.all();
			selectedSpouseIds = (selectedSpouseIds || []).filter(id => spouses.includes(id));
		}}
	/>
{/if}

<style lang="scss">
	.block-list {
		list-style-type: none;
		padding-left: 0;
	}
	.button_with_info_wrapper {
		position: relative;
		display: flex;
	}
	.order-of-repayment__popover {
		position: absolute;
		bottom: 1.2rem;
		left: 15px;
		z-index: 9999;
		width: 17vw;
		background-color: #fff;
		border: solid 1px;
		border-radius: 0.3rem;
		box-shadow: 0 0 10px;
		display: block;
		padding: 0.7rem 1rem;
		font-size: 0.81rem;
		color: #676a6c;
		font-weight: 400;
	}
</style>
