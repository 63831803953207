<!-- @format -->
<script>
	import { autosaveStatus, transmitter } from 'base_stores';
	import { onMount, createEventDispatcher } from 'svelte';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import { isPresent } from 'utils/tools';

	export let attribute;
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let label;
	export let required = false;
	export let disabled = true;
	export let value = [];
	export let options = [];
	export let placeholder;
	export let hintData;
	export let forceSave = false;
	export let additionalParams = {};
	export let saveAutomatically = true;
	export let requiredAttributes = {};

	const dispatch = createEventDispatcher();

	let dataErrors;
	let selectNode = null;
	let selectedOptions = [];
	let mounted = false;

	$: lungOptions = options.flatMap(opt => isPresent(opt.group) ? opt.subOptions : opt);
	$: selectedOptions = lungOptions.filter(option => value.includes(option.id));
	$: method = id ? fetchPut : fetchPost;
	$: attrId = `select2-search-${model}-${attribute}`;

	$: if (id) { url = `${url}/${id}` }

	$: if (mounted && forceSave) {
		forceSave = false
		saveValues()

		selectNode.val(null).trigger('change')
		//selectNode.select2('destroy');
		//qwert();
		selectNode.val(value).trigger('change')
	}

	const saveValues = () => {
		let params = { [model]: { [attribute]: selectedOptions.map(o => o.id), ...requiredAttributes }, [`${childModel}_id`]: childId, ...additionalParams };

		method(url, params)
			.then(response => {
				if (!forceSave && JSON.stringify(response) !== '{}') {
					$autosaveStatus = 'saved';
				}
				$transmitter = { ...$transmitter, ...response };
				dispatch('update', response);
			})
			.catch(_errors => {
				$autosaveStatus = 'not_saved';
			});
	};

	const qwert = () => {
		setSelect2();

		selectNode.on('select2:open', () => {
			document.querySelector('.select2-search__field').focus();
		});
		selectNode.on('select2:select', event => {
			selectedOptions = [...selectedOptions, lungOptions.find(opt => opt.id === event.params.data.id)];
			if (saveAutomatically) {
				saveValues();
			}
		});
		selectNode.on('select2:unselect', event => {
			selectedOptions = selectedOptions.filter(o => o.id !== event.params.data.id);
			if (saveAutomatically) {
				saveValues();
			}
		});
	}

	const setSelect2 = () => {
		selectNode.select2({
			placeholder,
			allowClear: false,
			disabled,
		});
	};

	onMount(() => {
		selectNode = jQuery(`#${attrId}`);

		qwert()

		mounted = true;
	});
</script>

<div class="form-group row" class:has-error={dataErrors}>
	<label for={attrId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<div class="select-wrapper" class:wrapper-active={!disabled}>
			<select multiple class="form-control" {disabled} id={attrId}>
				{#each options as option}
					{#if isPresent(option.group)}
						<optgroup label={option.group}>
							{#each option.subOptions || [] as subOption}
								<option value={subOption.id} selected={value.includes(subOption.id)}>
									{@html subOption.text}
								</option>
							{/each}
						</optgroup>
					{:else}
						<option value={option.id} selected={value.includes(option.id)}>
							{@html option.text}
						</option>
					{/if}
				{/each}
			</select>
		</div>
		{#if dataErrors}
			<InputErrorsString errors={dataErrors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>
