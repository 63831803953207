<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady, options } from 'base_stores';
	import { dctFormAvailable } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { isPresent } from 'utils/tools';
	import PossessionModal from '~/svelte/possessions/_modal.svelte';

	export let fieldId;
	export let header;

	let indexCollapse = false;
	let possessionModal;
	let procedureId = null;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	$: possessions = ($transmitter && $transmitter.possessions) || possessions || [];
	$: flattedPossessions = Object.values(possessions).flat(1);

	const handleNewPossessionItem = () => possessionModal.newItem();
	const handleEditPossessionItem = id => possessionModal.editItem(id);

	const loadPossessions = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/possessions', { procedure_id: procedureId });
			$transmitter = { ...$transmitter, possessions: response.possessions };
			$options = {
				...$options,
				possessionKind: response.kind_options.sort((a, b) => a.sequence - b.sequence),
				valuer: response.valuer_options,
			};
		} catch (error) {
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: canAddPossession = isPresent($transmitter.dct) ? $dctFormAvailable : true;

	$: if (!$transmitter.possessions && !fetching) {
		loadPossessions();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:keypress|preventDefault on:click={() => (indexCollapse = !indexCollapse)}>
	<h4 class="possessions col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`${header || 'Имущество (движимое, недвижимое и иное)'} (${flattedPossessions.length})`}</span>
	</h4>
</div>
<div id="collapse-possessions" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!canAddPossession} on:click={canAddPossession && handleNewPossessionItem}> + Объект </button>
		<div class="p-1">
			<ol class="block-list">
				{#if flattedPossessions.length}
					{#each flattedPossessions as possession}
						<li>
							<div class="float-left simulated-link" role="button" tabindex="0" on:keypress|preventDefault on:click={handleEditPossessionItem(possession.id)}>
								{possession.name}
							</div>
						</li>
					{/each}
				{/if}
			</ol>
		</div>
	</div>
</div>
<PossessionModal disabled={!canAddPossession} initBlank={true} bind:this={possessionModal} />

<style>
	.header {
		cursor: pointer;
	}
</style>
