<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dct, dctStatus, currentData } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { isPresent, isBlank } from 'utils/tools';
	import Case from 'case';
	import moment from 'moment';

	export let fieldId;

	let indexCollapse = false;
	let excludedDctKinds = ['arbitrary'];
	let date;
	let forceSave = true;
	let fetching = false;
	let reportingPeriodStart;
	let reportingPeriodFinish;

	const petitionsKinds = [
		'petition_for_publications_inclusion',
		'petition_for_introduction_of_bankruptcy_proceedings',
		'petition_to_transfer_to_property_realization',
		'petition_for_inclusion_of_materials_of_sk',
		'petition_of_postponement',
		'petition_for_familiarization_with_electronic_file',
		'petition_for_familiarization_with_materials_of_case',
		'application_for_issuance_of_copy_of_judicial_act',
		'petition_for_reclamation_of_evidence_by_zags',
		'petition_for_reclamation_of_evidence_by_sfr',
		'petition_for_reclamation_of_evidence',
	];

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	$: filteredSentNotificationRequests = ($transmitter && $transmitter.sentNotificationsRequests) || filteredSentNotificationRequests || [];

	const goToForm = dct => {
		window.open(`/documents/dcts/edit?id=${dct.id}`, '_blank');
	};

	const dctOutName = dct => {
		let name = (dct.substitutions && (dct.substitutions.correspondent_full_name || dct.substitutions.arbitrary_correspondent)) || dct.addressee_full_name;

		if (isBlank(name)) {
			name = dct.correspondent_kind_name || dct.addressee_kind_name;
		}

		if (petitionsKinds.includes(dct.kind)) {
			name = `АС (${dct.kind_name})`;
		}
		if (dct.correspondent_kind == 'court') {
			name = dct.substitutions.court_full_name;
		}
		if (dct.correspondent_kind == 'document') {
			name = dct.kind == 'arbitrary' ? dct.substitutions.arbitrary_correspondent : dct.kind_name;
		}
		if (dct.correspondent_kind == 'rosreestr') {
			name = `${name} (${Case.lower(dct.kind_name)})`;
		}
		if (dct.addressee_kind == 'rosreestr') {
			name = `${name} (${Case.lower(dct.document_kind_name)})`;
		}
		if (dct.addressee_kind_code == 'bank' && dct.document_kind_code == 'closure_request') {
			name = dct.contractor_short_name;
		}
		if (['bankrupt', 'newspaper', 'kdl'].includes(dct.correspondent_kind)) {
			name = dct.correspondent_kind_name;
		}
		if (['bankrupt', 'newspaper', 'kdl'].includes(dct.addressee_kind_code)) {
			name = dct.addressee_kind_name;
		}

		return `${name} от ${dct.date || dct.document_date ? moment(dct.date || dct.document_date, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''}`;
	};

	const loadSentDocuments = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/documents/sent_documents', {
				procedure_id: $procedure.id,
				date: $dct.date,
				date: null, //$currentData.reportingPeriod[1] || $dct.date,
				reporting_period_start: null, //$currentData.reportingPeriod[0],
				reporting_period_finish: null, //$currentData.reportingPeriod[1],
			});
			const filteredSentNotificationRequests = [
				...response.dcts.filter(dct => !excludedDctKinds.includes(dct.kind)),
				...response.dcts.filter(dct => dct.kind === 'arbitrary' && dct.substitutions.include_in_report),
			].sort((a, b) => (b.date < a.date ? 1 : -1));

			$transmitter = { ...$transmitter, sentNotificationsRequests: filteredSentNotificationRequests };
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	/*$: if ($currentData.reportingPeriod[0] != reportingPeriodStart || $currentData.reportingPeriod[1] != reportingPeriodFinish) {
		reportingPeriodStart = $currentData.reportingPeriod[0]
		reportingPeriodFinish = $currentData.reportingPeriod[1]
		loadSentDocuments()
	}*/

	$: if (!moment(date).isSame(moment($dct.date)) && !fetching) {
		date = $dct.date;
		loadSentDocuments();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="sent-notifications-requests col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Отправленные уведомления и запросы (${filteredSentNotificationRequests.length})`}</span>
	</h4>
</div>
<div id="collapse-sent-notifications-requests" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<div class="p-1">
			{#if isPresent(filteredSentNotificationRequests)}
				<ol class="block-list">
					{#each filteredSentNotificationRequests as dct}
						<li>
							<div
								class="float-left"
								class:simulated-link={procedureId && $dctStatus === 'draft'}
								class:simulated-link-disabled={!procedureId || $dctStatus === 'approved'}
								role="button"
								tabindex="0"
								on:click={procedureId && $dctStatus === 'draft' && goToForm(dct)}
								on:keypress|stopPropagation
							>
								{dctOutName(dct)}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>
