<!-- @format -->
<script>
	import { createEventDispatcher, tick } from 'svelte'
	import { isPresent, isArray, randomString } from 'utils/tools'
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte'
	import { transmitter } from 'base_stores'
	import PossessionForm from './_form.svelte'
	import RunningCostModal from '~/svelte/running_costs/_modal.svelte'
	import ReceiptModal from '~/svelte/receipts/_modal.svelte'
	import { cardsTitle } from 'utils/formats'
	import { clearScans } from '~/svelte/_shared/scans_sets/scans_set.js'

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let disabled = false;
	export let headerAddition;
	export let approvable = false;
	export let initBlank = false;
	export let modalId = 'modal-' + randomString();

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};
	export const editItem = id => {
		formOptions = { item: { id }, method: 'edit' };
		showModal(modalId);
	};
	export const showItem = id => {
		formOptions = { item: { id }, method: 'show' };
		showModal(modalId);
	};

	let formOptions = {}
	let form
	let formCompleted = false
	let runningCostModal
	let receiptModal
	let isNewInstance

	const possessionSubmit = async () => {
		const formData = form.getItem()
		const result = await form.submitForm()

		if (isPresent(result)) {
			if (isArray($transmitter.possessions)) {
				if (isPresent(result.item.onlyResidence)) {
					$transmitter.possessions = $transmitter.possessions
						.map(possession => possession.id != result.item.id ? { ...possession, onlyResidence: false } : result.item)
				} else {
					const possessions = (approvable && (result.item.status == 'draft')) ? 'draftPossessions' : 'possessions'

					$transmitter[possessions] =
						formOptions.method == 'edit'
							? $transmitter[possessions].map(possession => (possession.id !== result.item.id ? possession : result.item))
							: [...$transmitter[possessions], result.item];
				}

				const scansSet = await clearScans(formData.scans_set, 'deleted')
				$transmitter.possessions = $transmitter.possessions?.map(s => s.id == result.item.id ? { ...s, scans_set: scansSet } : s)
			}

			dispatch(formOptions.method == 'edit' ? 'update' : 'create', result)
			return result
		}
	}

	const possessionDelete = () =>
		form.deleteItem().then(id => {
			if (isArray($transmitter.possessions)) {
				$transmitter.possessions = $transmitter.possessions.filter(row => row.id != id);
			}
			if (isArray($transmitter.draftPossessions)) {
				$transmitter.draftPossessions = $transmitter.draftPossessions.filter(row => row.id != id);
			}

		dispatch('delete', id)
		return id
	})

	const possessionCancel = () => {
		const formData = form.getItem()
		if (isNewInstance) {
			if (formData?.id) { possessionDelete() }
		} else {
			clearScans(formData.scans_set, 'new')
		}
	}

	const possessionDuplicate = () => {
		const { id, ...rest } = form.getItem();
		formOptions = { item: { ...rest }, duplicateMode: true, method: 'new' };
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};

	const receiptEdit = ({ detail: id }) => receiptModal.editItem(id);
	const receiptDeleted = ({ detail: id }) => {
		const rows = form.getItem().receipts.filter(e => e.id != id);
		form.setItem({ receipts: rows });
	};
	const receiptUpdated = ({ detail: result }) => {
		const rows = form.getData().receipts;
		const row = rows.find(el => el.id == result.item.id);
		row.sum = result.item.sum;
		row.date_at = result.item.date_at;
		form.setData({ receipts: rows });
	};

	const runningCostEdit = ({ detail: id }) => runningCostModal.editItem(id);
	const runningCostDeleted = ({ detail: id }) => {
		const rows = form.getItem().running_costs.filter(e => e.id != id);
		form.setItem({ running_costs: rows });
	};
	const runningCostUpdated = ({ detail: result }) => {
		const rows = form.getData().running_costs;
		const row = rows.find(el => el.id == result.item.id);
		row.sum = result.item.sum;
		row.date_at = result.item.date_at;
		form.setData({ running_costs: rows });
	};

	$: approvableParams = approvable
		? {
				additionalActionButtonShow: true,
				additionalActionButtonText: 'Утвердить',
				additionalActionButtonAction: () => {
					const { id } = form.getItem();
					dispatch('approve', id);
				},
			}
		: {
				additionalActionButtonShow: false,
			};
</script>

<Modal
	{modalId}
	submitButtonDisabled={formOptions.method == 'show' || !formCompleted || disabled}
	submitButtonAction={possessionSubmit}
	deleteIconDisabled={formOptions.method == 'new' || disabled}
	deleteButtonAction={possessionDelete}
	cancelButtonAction={possessionCancel}
	deleteConfirmMessage='Вы действительно хотите удалить объект имущества?'
	showCopyAndSaveButton={ formOptions.method == 'edit' && !disabled }
	copyAndSaveButtonAction={possessionDuplicate}
	form={true}
	showError={false}
	{index}
	{...approvableParams}
	size='large'
	on:hide={() => isNewInstance = false}
>
	<h2 slot='header'>{cardsTitle('объекта имущества', formOptions.method == 'edit', headerAddition)}</h2>
	<div slot='body'>
		<PossessionForm
			{disabled}
			{initBlank}
			bind:this={form}
			bind:isCompleted={formCompleted}
			bind:isNewInstance
			{formOptions}
			on:editRunningCost={runningCostEdit}
			on:editReceipt={receiptEdit}
		/>
	</div>
</Modal>

<RunningCostModal {disabled} bind:this={runningCostModal} index={index + 1} on:update={runningCostUpdated} on:delete={runningCostDeleted} />
<ReceiptModal {disabled} bind:this={receiptModal} index={index + 1} hidePossession={true} on:update={receiptUpdated} on:delete={receiptDeleted} />
